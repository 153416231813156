<template>
    <div>
        <div class="toolBar mBottom10">
            <el-input size='small' placeholder="请输入Bean名称" style="width: 15%" clearable v-model="searchName">
                <i class="el-icon-search el-input__icon" style="cursor: pointer;" slot="suffix" @click="searchTask"></i>
            </el-input>

            <el-button plain size="medium" class='fr' style="margin-left: 10px" @click="goTaskLog">日志列表</el-button>
            <el-button type="primary" plain size="medium" class='fr' @click="executeTask">立即执行</el-button>
            <el-button type="primary" plain size="medium" class='fr' @click="resumeTask">恢复</el-button>
            <el-button type="primary" plain size="medium" class='fr' @click="pauseTask">暂停</el-button>
            <el-button type="primary" plain size="medium" class='fr' @click="delTask">删除</el-button>
            <el-button type="primary" plain size="medium" class='fr' @click="edit">修改</el-button>
            <el-button type="primary" plain size='medium' class='fr' @click="isShowAddDlg = true">新增</el-button>
        </div>

        <el-card class="box-card" style="margin-top: 10px">
            <el-table :data="tableData" size='small' stripe @current-change="handleCurrentChange" :height='tableHeight'>
                <el-table-column type="index" align="center" label='序号'></el-table-column>
                <el-table-column prop="beanName" label="bean名称" align="left"></el-table-column>
                <el-table-column prop="methodName" label="方法名称" align="left"></el-table-column>
                <el-table-column prop="params" label="方法参数" align="left"></el-table-column>
                <el-table-column prop="cronExpression" label="cron表达式" align="left"></el-table-column>
                <el-table-column prop="remark" label="备注" align="left"></el-table-column>
                <el-table-column prop="status" label="状态" align="center">
                    <template slot-scope="scope">
                            {{scope.row.status===0?'正常':'暂停'}}
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <div class="toolBar  clearfix" style="margin-top: 10px">
            <pagi-nation :page='page' @sizeChange='pageSizeChange' @currentChange='pageCurrentChange'></pagi-nation>
        </div>

        <!-- 增加 -->
        <el-dialog title="新增任务" v-dialogDrag :visible.sync="isShowAddDlg" width="40%">
            <el-form ref="roleForm" :model="addTaskData" :rules="rules" size='small' label-width="100px">
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="bean名称" prop="beanName">
                            <el-input clearable v-model="addTaskData.beanName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="方法名称" prop="methodName">
                            <el-input clearable v-model="addTaskData.methodName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="cron表达式" prop="cronExpression">
                            <el-popover v-model="cronPopover">
                                <cron @change="changeCron" @close="cronPopover=false" i18n="cn"></cron>
                                <el-input slot="reference" @click="cronPopover=true" v-model="addTaskData.cronExpression" placeholder="请输入定时策略"></el-input>
                            </el-popover>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="方法参数">
                            <el-input clearable v-model="addTaskData.params"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="备注">
                            <el-input clearable v-model="addTaskData.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
					<el-button @click="isShowAddDlg = false" size='small'>取消</el-button>
					<el-button type="primary" @click="addTask" size='small'>确定</el-button>
				  </span>
        </el-dialog>

        <!-- 修改 -->
        <el-dialog title="编辑任务" v-dialogDrag :visible.sync="isShowEditDlg" width="40%">
            <el-form ref="roleForm2" :model="currentItem" :rules="rules" size='small' label-width="100px">
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="bean名称" prop="beanName">
                            <el-input clearable v-model="currentItem.beanName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="方法名称" prop="methodName">
                            <el-input clearable v-model="currentItem.methodName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="cron表达式" prop="cronExpression">
                            <el-popover v-model="cronPopover2">
                                <cron @change="changeCron2" @close="cronPopover2=false" i18n="cn"></cron>
                                <el-input slot="reference" @click="cronPopover2=true" v-model="currentItem.cronExpression" placeholder="请输入定时策略"></el-input>
                            </el-popover>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="方法参数">
                            <el-input clearable v-model="currentItem.params"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="备注">
                            <el-input clearable v-model="currentItem.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isShowEditDlg = false" size='small'>取消</el-button>
                <el-button type="primary" @click="updateTask()" size='small'>确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {cron} from 'vue-cron';
    class Task {
        constructor() {
            this.beanName = null,
                this.cronExpression = '',
                this.methodName = null,
                this.params = null,
                this.remark = null
        }

        reset() {
            this.constructor();
        }
    };
    export default {
        components:{cron},
        data() {
            return {
                tableHeight: document.documentElement.clientHeight - 288,
                cronPopover:false,
                cronPopover2:false,
                tableData: [
                    {
                        name: "100", phone: "followUpPlanTask", idCard: "generateFollowUpTask",
                        fromType: "222", registerTime: "0 0 1 1/1 * ? *", status1: "每天凌晨1点开始执行", status2: "正常"
                    },
                    {
                        name: "100", phone: "followUpTaskStatusTask", idCard: "changeFollowUpTaskStatus",
                        fromType: "222", registerTime: "0 0 1 1/1 * ? *", status1: "每天凌晨1点开始执行", status2: "正常"
                    },
                    {
                        name: "100", phone: "followUpNotifyTask", idCard: "changeFollowUpTaskStatus",
                        fromType: "222", registerTime: "0 0 1 1/1 * ? *", status1: "每天凌晨1点开始执行", status2: "正常"
                    },
                    {
                        name: "100", phone: "followUpNotifyTask", idCard: "sendFollowUpNotify",
                        fromType: "222", registerTime: "0 0 1 1/1 * ? *", status1: "每天凌晨1点开始执行", status2: "正常"
                    },
                ],
                showCronBox:false,
                rules: {
                    beanName: [{required: true, message: 'bean名称是必须的', trigger: 'blur'}],
                    methodName: [{required: true, message: '方法名称是必须的', trigger: 'blur'}],
                    cronExpression: [{required: true, message: 'cron表达式是必须的', trigger: 'blur'}],
                },
                page: new this.Page(),

                isShowAddDlg: false,
                isShowEditDlg: false,
                isShowSelDlg: false,

                searchName:null,
                //新增
                addTaskData: new Task(),
                //修改
                currentItem: new Task(),
            }
        },
        computed : {

        },
        created() {
            this.load();
        },
        methods: {
            changeCron(val){
                this.addTaskData.cronExpression=val
            },
            changeCron2(val){
                this.currentItem.cronExpression=val
            },
            goTaskLog() {
                this.$router.push('taskLog');
            },
            pageSizeChange(val) {
                this.page.reset();
                this.page.pageSize = val;
                this.load();
            },
            pageCurrentChange(val) {
                this.page.currentPage = val;
                this.load();
            },
            handleCurrentChange(val) {
                Object.assign(this.currentItem,val);
            },
            async executeTask() {
                if (!this.currentItem.jobId) {
                    this.$message.warning('请先选择一列');
                } else {
                    let res = await this.$http.post('/quartz/executeScheduleJob',{jobIds:[this.currentItem.jobId]})
                    if(res.state){
                        this.$message.success('立即执行成功');
                    }else{
                        if(res.msg){
                            this.$message.error(res.msg);
                        }
                    }
                }
            },
            async resumeTask() {
                if (!this.currentItem.jobId) {
                    this.$message.warning('请先选择一列');
                } else {
                    let res = await this.$http.post('/quartz/resumeScheduleJob',{jobIds:[this.currentItem.jobId]})
                    if(res.state){
                        this.$message.success('恢复任务成功');
                        this.load();
                    }else{
                        if(res.msg){
                            this.$message.error(res.msg);
                        }
                    }
                }
            },
            async pauseTask() {
                if (!this.currentItem.jobId) {
                    this.$message.warning('请先选择一列');
                } else {
                    let res = await this.$http.post('/quartz/pauseScheduleJob',{jobIds:[this.currentItem.jobId]})
                    if(res.state){
                        this.$message.success('暂停任务成功');
                        this.load();
                    }else{
                        if(res.msg){
                            this.$message.error(res.msg);
                        }
                    }
                }
            },
            edit() {
                if (!this.currentItem.jobId) {
                    this.$message.warning('请先选择一列');
                } else {
					this.isShowEditDlg = true;
                }
            },
            async delTask(){
                if (!this.currentItem.jobId) {
                    this.$message.warning('请先选择一列');
                } else {
                    let res = await this.$http.post('/quartz/deleteScheduleJob',{jobIds:[this.currentItem.jobId]})
                    if(res.state){
                        this.$message.success('操作成功');
                        this.load();
                    }else{
                        if(res.msg){
                            this.$message.error(res.msg);
                        }
                    }
                }
            },
            async load() {
                let param = {
                    beanName: this.searchName,
                    limit: this.page.pageSize,
                    offSet: (this.page.currentPage - 1) * this.page.pageSize
                };
                let res = await this.$http.post('/quartz/getScheduleJobList', param);
                if (res.state) {
                    this.tableData = res.data.list;
                    this.page.total = res.data.totalCount;
                }
            },
            async addTaskRequest() {
                console.log(this.addTaskData)
                let res = await this.$http.post('/quartz/addScheduleJob', this.addTaskData);
                if (res.state) {
                    this.$message.success('操作成功');
                    this.addTaskData.reset();
                }
            },
            addTask() {
                this.$refs['roleForm'].validate((valid) => {
                    if (valid) {
                        this.addTaskRequest();
                        this.isShowAddDlg = false;
                        this.page.reset();
                        this.load();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async updateTaskRequest() {
                let res = await this.$http.post('/quartz/updateScheduleJob', this.currentItem);
                if (res.state) {
                    this.$message.success('操作成功');
                    this.isShowEditDlg = false;
                    this.load();
                }
            },
            updateTask() {
                this.$refs['roleForm2'].validate((valid) => {
                    if (valid) {
                        this.updateTaskRequest();
                        this.isShowEditDlg = false;
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            editTask(data) {
                console.log(data);
                Object.assign(this.editTaskData, data);
                this.isShowEditDlg = true;
            },
            searchTask() {
                this.page.reset();
                this.load();
            },
        },
        mounted() {
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight
                this.tableHeight = window.fullHeight - 120;
            }
        }
    }
</script>

<style>
</style>